import React, { useEffect, useState, useRef } from "react";

function Carousel() {
  const images = [
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/VCS_1.jpeg",
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/VCS_1.jpeg",
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/VCS_1.jpeg",
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/VCS_1.jpeg",
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/VCS_1.jpeg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const nextIndex = (currentIndex + 1) % images.length;
    timeoutRef.current = setTimeout(() => {
      setCurrentIndex(nextIndex);
    }, 3000);

    return () => clearTimeout(timeoutRef.current);
  }, [currentIndex, images.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    clearTimeout(timeoutRef.current);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        {images.map((img, index) => (
          <div
            key={index}
            className="carousel-image"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            <img src={img} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
      <style jsx>{`
        .carousel-container {
          overflow: hidden;
          width: 100%;
          max-width: 100%;
          position: relative;
        }

        .carousel {
          display: flex;
          transition: transform 0.8s ease-in-out; /* Smooth animation */
        }

        .carousel-image {
          width: 100%;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
          height: auto;
          max-height: 33.33vw;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .carousel-dots {
          text-align: center;
          position: absolute;
          bottom: 10px;
          width: 100%;
        }

        .dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin: 0 5px;
          background-color: #ccc;
          border-radius: 50%;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .dot.active {
          background-color: #333;
        }

        @media (max-width: 768px) {
          img {
            max-height: auto;
          }
        }
      `}</style>
    </div>
  );
}

export default Carousel;
