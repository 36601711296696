import React from "react";
import books from "../utils/books";
import "./Books.css"; // Ensure this is linked correctly

function Books() {
  return (
    <div className="books-main-container">
      <h1 className="books-heading">Textbooks</h1>
      <p className="details-link">
        <a
          href="https://mptbc.mp.gov.in/web04/BookDetails.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          View All Book Online
        </a>
      </p>
      <div className="attention-message">
        <p>
          NCERT books are used in the school. All class books and their names
          are listed below. Students can purchase these books from any
          bookstore. Thank you.
        </p>
      </div>
      <div className="books-container">
        {books.map((bookClass, index) => (
          <div className="class-section" key={index}>
            <h2>{bookClass.class}</h2>
            <p>
              Publication: <strong>{bookClass.publication}</strong>
            </p>
            <ul>
              {bookClass.subjects.map((subjectDetails, subIndex) => (
                <li key={subIndex} className="subject-item">
                  <h3>{subjectDetails.subject}</h3>
                  <p>
                    Book Name: <strong>{subjectDetails.bookName}</strong>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Books;
