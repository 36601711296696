import React, { useState } from "react";
import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import "./Footer.css";
import school from "../utils/school";

function Footer() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the mailto link
    const mailtoLink = `mailto:${
      school.SCHOOL_EMAIL
    }?subject=Contact Us&body=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
    )}`;

    // Open the mailto link
    window.location.href = mailtoLink;

    // Reset form
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-details">
          <h2>Contact Information</h2>
          <p>{school.SCHOOL_NAME}</p>
          <p>Mobile: {school.SCHOOL_PHONE}</p>
          <p>Address: {school.SCHOOL_ADDRESS}</p>
          <p>
            Email:{" "}
            <a href={`mailto:${school.SCHOOL_EMAIL}`}>{school.SCHOOL_EMAIL}</a>
          </p>
          <a href={school.LOCATION} target="_blank" rel="noopener noreferrer">
            <button className="direction-button">Get Directions</button>
          </a>
          <div className="social-media-buttons">
            <a
              href={school.WHATSAPP_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="social-icon" />
            </a>
            <a
              href={school.INSTAGRAM_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="social-icon" />
            </a>
            <a
              href={school.FACEBOOK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="social-icon" />
            </a>
          </div>
        </div>
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
