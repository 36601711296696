import {
    APJ_Abdul_kalam,
    Albert_Einstein,
    Doherty_Henry,
    Franklin,
    Swami_vivekanand,
  } from "../static";


  const thoughts = [
    {
      id: 1,
      author: "स्वामी विवेकानन्द",
      thought:
        "किसी भी कार्य करने के लिए तुरन्त उठो, जागो और तब तक नही रुकना जब तक लक्ष्य हासिल न हो जाए",
      image: Swami_vivekanand,
      background: "#F5F5F5", // Light grey background
      textColor: "#333333", // Dark grey text
    },
    {
      id: 2,
      author: "एपीजे अब्दुल कलाम ",
      thought:
        "एक बेहतरीन किताब 100 अच्छे दोस्त के बराबर है, लेकिन एक सर्वश्रेष्ठ दोस्त पुस्तकालय के बराबर है।",
      image: APJ_Abdul_kalam,
      background: "#FFEBEE", // Light pink
      textColor: "#C62828", // Red color
    },
    {
      id: 3,
      author: "अल्बर्ट आइन्स्टीन",
      thought:
        "शिक्षा का उद्देश्य तथ्यों को सीखना नहीं होता है बल्कि शिक्षा का मुख्य दिमाग को प्रशिक्षित करना होता है।",
      image: Albert_Einstein,
      background: "#FFF3E0", // Light orange
      textColor: "#EF6C00", // Dark orange
    },
    {
      id: 4,
      author: "अल्बर्ट आइन्स्टीन",
      thought:
        "शिक्षा का उद्देश्य तथ्यों को सीखना नहीं होता है बल्कि शिक्षा का मुख्य दिमाग को प्रशिक्षित करना होता है।",
      image: Albert_Einstein,
      background: "#EDE7F6", // Light lavender
      textColor: "#5E35B1", // Dark lavender
    },
    {
      id: 5,
      author: "एपीजे अब्दुल कलाम ",
      thought:
        "एक छात्र की सबसे महत्वपूर्ण गुण यह है कि वह हमेशा अपने अध्यापक से सवाल पूछे।",
      image: APJ_Abdul_kalam,
      background: "#F5F5F5", // Light grey background
      textColor: "#333333", // Dark grey text
    },
    {
      id: 6,
      author: "बेंजामिन फ्रैंकलिन",
      thought:
        "हमने स्कूल में जो सीखा है वह सब भूलने के बाद जो याद रहता है, वही शिक्षा है। ज्ञान का निवेश सर्वोत्तम भुगतान करता है।",
      image: Franklin,
      background: "#E0F2F1", // Light aqua
      textColor: "#004D40", // Teal color
    },
    {
      id: 8,
      author: "स्वामी विवेकानंद",
      thought:
        "अपने जीवन का लक्ष्य निर्धारित करो और सभी दूसरे विचार को अपने दिमाग से निकाल दो यही सफलता की पूंजी है",
      image: Swami_vivekanand,
      background: "#E8F5E9", // Light green
      textColor: "#2E7D32", // Dark green
    },
    {
      id: 9,
      author: "हेनरी एल डोहर्टी",
      thought:
        "आप तभी तक सीख सकते है जब तक आप खुद को एक छात्र मान पाते है क्योंकि कुछ सीखने के लिए झुकना पड़ता है",
      image: Doherty_Henry,
      background: "#FFF3E0", // Light orange
      textColor: "#EF6C00", // Dark orange
    },
  ];
  
  export default thoughts;