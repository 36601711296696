import React from "react";
// Assuming you've saved the thoughts data in a separate file 'thoughts.js' in the utils folder
import thoughts from "../utils/thoughts";

function GridDisplay() {
  return (
    <div className="container">
      <h1 className="heading">Inspiring Thoughts from Great Minds</h1>
      <div className="grid-container">
        {thoughts.map((thought) => (
          <div
            key={thought.id}
            className="grid-item"
            style={{
              backgroundColor: thought.background,
              color: thought.textColor,
            }}
          >
            <div className="image-wrapper">
              <img
                src={thought.image}
                alt={thought.author}
                className="author-image"
              />
            </div>
            <div className="thought-content">
              <h3>{thought.author}</h3>
              <p>{thought.thought}</p>
            </div>
          </div>
        ))}
      </div>
      <style jsx>{`
        .container {
          padding: 40px 20px;
          max-width: 1200px;
          margin: 0 auto;
          text-align: center;
        }

        .heading {
          font-size: 2.5rem;
          color: #2a3d66;
          margin-bottom: 40px;
        }

        .grid-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
        }

        .grid-item {
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          text-align: center;
        }

        .grid-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .image-wrapper {
          margin-bottom: 15px;
        }

        .author-image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
        }

        .thought-content h3 {
          margin-bottom: 10px;
          font-size: 1.2rem;
        }

        .thought-content p {
          font-size: 1rem;
          line-height: 1.4;
        }

        @media (max-width: 768px) {
          .heading {
            font-size: 2rem;
          }
        }

        @media (max-width: 480px) {
          .heading {
            font-size: 1.5rem;
          }

          .author-image {
            width: 60px;
            height: 60px;
          }
        }
      `}</style>
    </div>
  );
}

export default GridDisplay;
