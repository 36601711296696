import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaBook,
  FaMoneyCheckAlt,
  FaEnvelope,
} from "react-icons/fa";
import "./Header.css";
import school from "../utils/school";

function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // Close drawer and scroll to section
  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    const target = e.currentTarget.getAttribute("href"); // Get the target section
    if (target === "#footer") {
      // If the footer link is clicked, scroll to the bottom
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsDrawerOpen(false); // Close the drawer when a link is clicked
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={school.SCHOOL_LOGO} alt="School Logo" className="logo" />
        <h1 className="school-name">Vivekanand Convent School Katra</h1>
      </div>
      <div className="nav-bar">
        {isDrawerOpen ? (
          <FaTimes className="menu-icon" onClick={toggleDrawer} />
        ) : (
          <FaBars className="menu-icon" onClick={toggleDrawer} />
        )}
        <nav className={`nav ${isDrawerOpen ? "open" : ""}`}>
          <ul className="nav-links">
            <li>
              <a href="#home">
                <FaHome className="nav-icon" /> Home
              </a>
            </li>
            <li>
              <a href="#books">
                <FaBook className="nav-icon" /> Books
              </a>
            </li>
            <li>
              <a href="#fees">
                <FaMoneyCheckAlt className="nav-icon" /> Fee Structure
              </a>
            </li>
            <li>
              <a href="#footer" onClick={handleLinkClick}>
                <FaEnvelope className="nav-icon" /> Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}
    </header>
  );
}

export default Header;
