const books = [
  {
    class: "Class 1st",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 1",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 1",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths Magic - 1",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 2nd",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 2",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 2",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths Magic - 2",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 3rd",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 3",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 3",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths Magic - 3",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Environmental Studies",
        bookName: "Environmental studies looking around 3",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 4th",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 4",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 4",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths Magic - 4",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Environmental Studies",
        bookName: "Environmental studies looking around 4",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 5th",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 5",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 5",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths Magic - 5",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Environmental Studies",
        bookName: "Environmental studies looking around 5",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 6th",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Science",
        bookName: "Science 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Social Science",
        bookName: "Social Science 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sanskrit",
        bookName: "सुरभि:",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sahaayak Vaachan",
        bookName: "Sahaayak Vaachan 6",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 7th",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Science",
        bookName: "Science 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Social Science",
        bookName: "Social Science 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sanskrit",
        bookName: "सुरभि: -7 ",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sahaayak Vaachan",
        bookName: "Sahaayak Vaachan 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
  {
    class: "Class 8th",
    publication: "NCERT",
    subjects: [
      {
        subject: "Hindi",
        bookName: "भाषा भारती - 8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Maths",
        bookName: "Maths 8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Science",
        bookName: "Science 8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Social Science",
        bookName: "Social Science 8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sanskrit",
        bookName: "सुरभि: -8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "English",
        bookName: "English Reader - 8",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
      {
        subject: "Sahaayak Vaachan",
        bookName: "Sahaayak Vaachan 7",
        publication: "NCERT",
        link: "https://mptbc.mp.gov.in/web04/BookDetails.aspx",
      },
    ],
  },
];

export default books;
