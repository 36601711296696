import React from 'react';

function PrincipalMessage() {
  const message = "शिक्षा मसीहा है जो एक उज्ज्वल भविष्य के मार्ग को प्रकाशित करता है। यह व्यक्तियों को उनकी पूरी क्षमता को खोलने की शक्ति प्रदान करती है, अहम विचारशीलता, रचनात्मकता, और समस्या समाधान कौशल को पोषण देती है। शिक्षा के माध्यम से हम न केवल ज्ञान प्राप्त करते हैं बल्कि दया, सहनशीलता, और हमारे चारों ओर की दुनिया को गहराई से समझना करने की भी क्षमता विकसित करते हैं। यह व्यक्तिगत विकास, सामाजिक प्रगति, और वैश्विक समारोह के लिए मार्ग प्रशस्त करती है। शिक्षा वह कुंजी है जो अनगिनत संभावनाओं के रास्ते खोलती है, हमें सूचित, जिम्मेदार वैश्विक नागरिक बनाती है जो एक बेहतर कल को सूचित करने में सक्षम होते हैं।";

  const imageUrl = "https://raw.githubusercontent.com/ajprince21/Static/main/images/MUNNA_LAL_TIWARI.png";

  return (
    <div className="principal-message-container">
      <img src={imageUrl} alt="Principal" className="principal-image" />
      <div className="principal-message">
        <h2>Principal's Message</h2>
        <p>{message}</p>
      </div>
      <style jsx>{`
        .principal-message-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 30px;
          margin: 20px auto;
          max-width: 850px;
          background-color: #ffffff;
          border-radius: 15px;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
          transition: transform 0.4s ease, box-shadow 0.4s ease; /* Smooth transition */
        }

        .principal-message-container:hover {
          transform: translateY(-8px);
          box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
        }

        .principal-image {
          width: 160px;
          height: 160px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 20px;
          border: 4px solid #2a3d66; /* Additional color emphasis */
        }

        .principal-message {
          font-size: 1.1rem;
          color: #333;
          line-height: 1.8;
          font-family: 'Roboto', sans-serif; /* Stylish and readable font */
        }

        .principal-message h2 {
          font-size: 1.8rem;
          color: #2a3d66;
          margin-bottom: 15px;
        }

        @media (max-width: 768px) {
          .principal-message-container {
            padding: 20px;
          }

          .principal-message h2 {
            font-size: 1.5rem;
          }

          .principal-message p {
            font-size: 1rem;
          }
        }

        @media (max-width: 480px) {
          .principal-message-container {
            padding: 15px;
          }
          
          .principal-image {
            width: 120px;
            height: 120px;
          }
          
          .principal-message h2 {
            font-size: 1.3rem;
          }

          .principal-message p {
            font-size: 0.9rem;
          }
        }
      `}</style>
    </div>
  );
}

export default PrincipalMessage;
