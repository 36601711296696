import React from "react";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import Books from "./components/Books";
import FeeStructure from "./components/FeeStructure";
import PrincipalMessage from "./components/PrincipalMessage";
import Footer from "./components/Footer";
import "./App.css";
import GridDisplay from "./components/GridDisplay";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <div id="home">
          <Carousel />
        </div>
        <div id="books">
          <Books />
        </div>
        <div id="fees">
          <FeeStructure />
        </div>
        <div id="grid-display">
          <GridDisplay />
        </div>
        <div id="principal-message">
          <PrincipalMessage />
        </div>
        <Footer id="footer" /> {/* Ensure Footer component has id="footer" */}
      </main>
    </div>
  );
}

export default App;
