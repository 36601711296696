import { SCHOOL_LOGO } from "../static";

const school = {
  SCHOOL_NAME: "Vivekanand Convent School Katra",
  LOCATION: "https://maps.app.goo.gl/hWX732M85kze4KBR7",
  SCHOOL_EMAIL: "vcskatra@gmail.com",
  SCHOOL_PHONE: "+91 9229460042",
  SCHOOL_ADDRESS: "Near M.P.E.B office, Katra, Rewa, MP 486117",
  WHATSAPP_LINK: `https://api.whatsapp.com/send?phone=919229460042&text=Hello%20Sir`,
  FACEBOOK_LINK: "https://www.facebook.com/vcskatra/",
  INSTAGRAM_LINK: "https://www.instagram.com/vcskatra/",
  PRINCIPAL_MESSAGE:
    "शिक्षा मसीहा है जो एक उज्ज्वल भविष्य के मार्ग को प्रकाशित करता है। यह व्यक्तियों को उनकी पूरी क्षमता को खोलने की शक्ति प्रदान करती है, अहम विचारशीलता, रचनात्मकता, और समस्या समाधान कौशल को पोषण देती है। शिक्षा के माध्यम से हम न केवल ज्ञान प्राप्त करते हैं बल्कि दया, सहनशीलता, और हमारे चारों ओर की दुनिया को गहराई से समझना करने की भी क्षमता विकसित करते हैं। यह व्यक्तिगत विकास, सामाजिक प्रगति, और वैश्विक समारोह के लिए मार्ग प्रशस्त करती है। शिक्षा वह कुंजी है जो अनगिनत संभावनाओं के रास्ते खोलती है, हमें सूचित, जिम्मेदार वैश्विक नागरिक बनाती है जो एक बेहतर कल को सूचित करने में सक्षम होते हैं।",
  PRINCIPAL_IMAGE:
    "https://raw.githubusercontent.com/ajprince21/Static/main/images/MUNNA_LAL_TIWARI.png",
  SCHOOL_LOGO: SCHOOL_LOGO,
};

export default school;
